import $ from 'jquery';
import double_metaphone from 'src/double_metaphone';

const ACCENT_MAP = {
  À: 'A',
  Á: 'A',
  Â: 'A',
  Ã: 'A',
  Ä: 'A',
  Å: 'A',
  Ấ: 'A',
  Ắ: 'A',
  Ẳ: 'A',
  Ẵ: 'A',
  Ặ: 'A',
  Æ: 'AE',
  Ầ: 'A',
  Ằ: 'A',
  Ȃ: 'A',
  Ç: 'C',
  Ḉ: 'C',
  È: 'E',
  É: 'E',
  Ê: 'E',
  Ë: 'E',
  Ế: 'E',
  Ḗ: 'E',
  Ề: 'E',
  Ḕ: 'E',
  Ḝ: 'E',
  Ȇ: 'E',
  Ì: 'I',
  Í: 'I',
  Î: 'I',
  Ï: 'I',
  Ḯ: 'I',
  Ȋ: 'I',
  Ð: 'D',
  Ñ: 'N',
  Ò: 'O',
  Ó: 'O',
  Ô: 'O',
  Õ: 'O',
  Ö: 'O',
  Ø: 'O',
  Ố: 'O',
  Ṍ: 'O',
  Ṓ: 'O',
  Ȏ: 'O',
  Ù: 'U',
  Ú: 'U',
  Û: 'U',
  Ü: 'U',
  Ý: 'Y',
  à: 'a',
  á: 'a',
  â: 'a',
  ã: 'a',
  ä: 'a',
  å: 'a',
  ấ: 'a',
  ắ: 'a',
  ẳ: 'a',
  ẵ: 'a',
  ặ: 'a',
  æ: 'ae',
  ầ: 'a',
  ằ: 'a',
  ȃ: 'a',
  ç: 'c',
  ḉ: 'c',
  è: 'e',
  é: 'e',
  ê: 'e',
  ë: 'e',
  ế: 'e',
  ḗ: 'e',
  ề: 'e',
  ḕ: 'e',
  ḝ: 'e',
  ȇ: 'e',
  ì: 'i',
  í: 'i',
  î: 'i',
  ï: 'i',
  ḯ: 'i',
  ȋ: 'i',
  ð: 'd',
  ñ: 'n',
  ò: 'o',
  ó: 'o',
  ô: 'o',
  õ: 'o',
  ö: 'o',
  ø: 'o',
  ố: 'o',
  ṍ: 'o',
  ṓ: 'o',
  ȏ: 'o',
  ù: 'u',
  ú: 'u',
  û: 'u',
  ü: 'u',
  ý: 'y',
  ÿ: 'y',
  Ā: 'A',
  ā: 'a',
  Ă: 'A',
  ă: 'a',
  Ą: 'A',
  ą: 'a',
  Ć: 'C',
  ć: 'c',
  Ĉ: 'C',
  ĉ: 'c',
  Ċ: 'C',
  ċ: 'c',
  Č: 'C',
  č: 'c',
  C̆: 'C',
  c̆: 'c',
  Ď: 'D',
  ď: 'd',
  Đ: 'D',
  đ: 'd',
  Ē: 'E',
  ē: 'e',
  Ĕ: 'E',
  ĕ: 'e',
  Ė: 'E',
  ė: 'e',
  Ę: 'E',
  ę: 'e',
  Ě: 'E',
  ě: 'e',
  Ĝ: 'G',
  Ǵ: 'G',
  ĝ: 'g',
  ǵ: 'g',
  Ğ: 'G',
  ğ: 'g',
  Ġ: 'G',
  ġ: 'g',
  Ģ: 'G',
  ģ: 'g',
  Ĥ: 'H',
  ĥ: 'h',
  Ħ: 'H',
  ħ: 'h',
  Ḫ: 'H',
  ḫ: 'h',
  Ĩ: 'I',
  ĩ: 'i',
  Ī: 'I',
  ī: 'i',
  Ĭ: 'I',
  ĭ: 'i',
  Į: 'I',
  į: 'i',
  İ: 'I',
  ı: 'i',
  Ĳ: 'IJ',
  ĳ: 'ij',
  Ĵ: 'J',
  ĵ: 'j',
  Ķ: 'K',
  ķ: 'k',
  Ḱ: 'K',
  ḱ: 'k',
  K̆: 'K',
  k̆: 'k',
  Ĺ: 'L',
  ĺ: 'l',
  Ļ: 'L',
  ļ: 'l',
  Ľ: 'L',
  ľ: 'l',
  Ŀ: 'L',
  ŀ: 'l',
  Ł: 'l',
  ł: 'l',
  Ḿ: 'M',
  ḿ: 'm',
  M̆: 'M',
  m̆: 'm',
  Ń: 'N',
  ń: 'n',
  Ņ: 'N',
  ņ: 'n',
  Ň: 'N',
  ň: 'n',
  ŉ: 'n',
  N̆: 'N',
  n̆: 'n',
  Ō: 'O',
  ō: 'o',
  Ŏ: 'O',
  ŏ: 'o',
  Ő: 'O',
  ő: 'o',
  Œ: 'OE',
  œ: 'oe',
  P̆: 'P',
  p̆: 'p',
  Ŕ: 'R',
  ŕ: 'r',
  Ŗ: 'R',
  ŗ: 'r',
  Ř: 'R',
  ř: 'r',
  R̆: 'R',
  r̆: 'r',
  Ȓ: 'R',
  ȓ: 'r',
  Ś: 'S',
  ś: 's',
  Ŝ: 'S',
  ŝ: 's',
  Ş: 'S',
  Ș: 'S',
  ș: 's',
  ş: 's',
  Š: 'S',
  š: 's',
  Ţ: 'T',
  ţ: 't',
  ț: 't',
  Ț: 'T',
  Ť: 'T',
  ť: 't',
  Ŧ: 'T',
  ŧ: 't',
  T̆: 'T',
  t̆: 't',
  Ũ: 'U',
  ũ: 'u',
  Ū: 'U',
  ū: 'u',
  Ŭ: 'U',
  ŭ: 'u',
  Ů: 'U',
  ů: 'u',
  Ű: 'U',
  ű: 'u',
  Ų: 'U',
  ų: 'u',
  Ȗ: 'U',
  ȗ: 'u',
  V̆: 'V',
  v̆: 'v',
  Ŵ: 'W',
  ŵ: 'w',
  Ẃ: 'W',
  ẃ: 'w',
  X̆: 'X',
  x̆: 'x',
  Ŷ: 'Y',
  ŷ: 'y',
  Ÿ: 'Y',
  Y̆: 'Y',
  y̆: 'y',
  Ź: 'Z',
  ź: 'z',
  Ż: 'Z',
  ż: 'z',
  Ž: 'Z',
  ž: 'z',
  ſ: 's',
  ƒ: 'f',
  Ơ: 'O',
  ơ: 'o',
  Ư: 'U',
  ư: 'u',
  Ǎ: 'A',
  ǎ: 'a',
  Ǐ: 'I',
  ǐ: 'i',
  Ǒ: 'O',
  ǒ: 'o',
  Ǔ: 'U',
  ǔ: 'u',
  Ǖ: 'U',
  ǖ: 'u',
  Ǘ: 'U',
  ǘ: 'u',
  Ǚ: 'U',
  ǚ: 'u',
  Ǜ: 'U',
  ǜ: 'u',
  Ứ: 'U',
  ứ: 'u',
  Ṹ: 'U',
  ṹ: 'u',
  Ǻ: 'A',
  ǻ: 'a',
  Ǽ: 'AE',
  ǽ: 'ae',
  Ǿ: 'O',
  ǿ: 'o',
  Þ: 'TH',
  þ: 'th',
  Ṕ: 'P',
  ṕ: 'p',
  Ṥ: 'S',
  ṥ: 's',
  X́: 'X',
  x́: 'x',
  Ѓ: 'Г',
  ѓ: 'г',
  Ќ: 'К',
  ќ: 'к',
  A̋: 'A',
  a̋: 'a',
  E̋: 'E',
  e̋: 'e',
  I̋: 'I',
  i̋: 'i',
  Ǹ: 'N',
  ǹ: 'n',
  Ồ: 'O',
  ồ: 'o',
  Ṑ: 'O',
  ṑ: 'o',
  Ừ: 'U',
  ừ: 'u',
  Ẁ: 'W',
  ẁ: 'w',
  Ỳ: 'Y',
  ỳ: 'y',
  Ȁ: 'A',
  ȁ: 'a',
  Ȅ: 'E',
  ȅ: 'e',
  Ȉ: 'I',
  ȉ: 'i',
  Ȍ: 'O',
  ȍ: 'o',
  Ȑ: 'R',
  ȑ: 'r',
  Ȕ: 'U',
  ȕ: 'u',
  B̌: 'B',
  b̌: 'b',
  Č̣: 'C',
  č̣: 'c',
  Ê̌: 'E',
  ê̌: 'e',
  F̌: 'F',
  f̌: 'f',
  Ǧ: 'G',
  ǧ: 'g',
  Ȟ: 'H',
  ȟ: 'h',
  J̌: 'J',
  ǰ: 'j',
  Ǩ: 'K',
  ǩ: 'k',
  M̌: 'M',
  m̌: 'm',
  P̌: 'P',
  p̌: 'p',
  Q̌: 'Q',
  q̌: 'q',
  Ř̩: 'R',
  ř̩: 'r',
  Ṧ: 'S',
  ṧ: 's',
  V̌: 'V',
  v̌: 'v',
  W̌: 'W',
  w̌: 'w',
  X̌: 'X',
  x̌: 'x',
  Y̌: 'Y',
  y̌: 'y',
  A̧: 'A',
  a̧: 'a',
  B̧: 'B',
  b̧: 'b',
  Ḑ: 'D',
  ḑ: 'd',
  Ȩ: 'E',
  ȩ: 'e',
  Ɛ̧: 'E',
  ɛ̧: 'e',
  Ḩ: 'H',
  ḩ: 'h',
  I̧: 'I',
  i̧: 'i',
  Ɨ̧: 'I',
  ɨ̧: 'i',
  M̧: 'M',
  m̧: 'm',
  O̧: 'O',
  o̧: 'o',
  Q̧: 'Q',
  q̧: 'q',
  U̧: 'U',
  u̧: 'u',
  X̧: 'X',
  x̧: 'x',
  Z̧: 'Z',
  z̧: 'z',
  0: 'zero',
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
  6: 'six',
  7: 'seven',
  8: 'eight',
  9: 'nine',
};
const ACCENT_CHARS = Object.keys(ACCENT_MAP).join('|');

export const BACKSPACE = 8;
export const TAB = 9;
export const ENTER = 13;
export const ESC = 27;
export const SPACE = 32;
export const ARROW_LEFT = 37;
export const ARROW_UP = 38;
export const ARROW_RIGHT = 39;
export const ARROW_DOWN = 40;

export function blank(str) {
  return /^\s*$/.test(str || '') || str == '&nbsp;';
}

export function focusFirstField(container) {
  const firstField = $(container)
    .find(
      "input[type='text']:visible,input[type='tel']:visible,textarea:visible,select:visible,.autofocus,.ms-choice,.select-trigger-area"
    )
    .first();
  firstField.trigger('focus').trigger('select');
  // Open the first select by default if it's mandatory
  if (
    firstField.hasClass('ms-choice') ||
    firstField.hasClass('select-trigger-area')
  ) {
    const inDialog = firstField.parents('.modal-content').length > 0;
    const isMandatoryField = firstField.parent('.mandatory').length > 0;
    if (!inDialog && isMandatoryField) {
      firstField.trigger('click');
    }
  }
}

export function removeAccent(string) {
  const allAccents = new RegExp(ACCENT_CHARS, 'g');
  return string.replace(allAccents, (match) => ACCENT_MAP[match]);
}

export function doubleMetaphone(string) {
  return double_metaphone(removeAccent(string)).primary;
}

export function decodeHtml(str) {
  const parser = new DOMParser();
  const parserDocument = parser.parseFromString(str, 'text/html');
  return parserDocument.body.textContent;
}

export function clone($node, with_handler = false) {
  return $node.clone(with_handler);
}

export function divForField($field) {
  return $field.parents('div.form-group');
}

export function intersection(array1, array2) {
  return array1.filter((value) => array2.includes(value));
}

export function union(array1, array2) {
  return Array.from(new Set([...array1, ...array2]));
}

export function firefox() {
  return window.mozInnerScreenX !== undefined;
}

export function replaceSelect(
  select,
  options,
  with_handler = false,
  multipleSelect = true
) {
  if (!select.length) return;
  const new_select = clone(select, with_handler);
  new_select.html('');
  new_select.append(options);
  new_select.insertAfter(select);
  select.detach();
  if (multipleSelect) {
    new_select.data('multipleSelect', null);
    new_select.multipleSelect();
  }
  return new_select;
}

export function replaceMultipleSelect(
  select,
  collection,
  with_handler = false
) {
  if (!select.length) return;
  const new_select = clone(select, with_handler);
  new_select.html('');
  collection.forEach((value) => {
    const option = `<option value="${value[1]}">${value[0]}</option>`;
    new_select.append(option);
  });
  const width = select.next('.ms-parent').width();
  new_select.insertAfter(select);
  select.detach();
  new_select.next('.ms-parent').remove();
  new_select.multipleSelect({ width: width });
  return new_select;
}

export function xmlToString(xml_node) {
  if (window.XMLSerializer) {
    return new XMLSerializer().serializeToString(xml_node);
  }
  return 'Browser not supported';
}

export function getScrollbarWidth() {
  const div = $(
    '<div style="width:50px;height:50px;overflow:hidden;position:absolute;top:-200px;left:-200px;"><div style="height:100px;"></div></div>'
  );
  $('body').append(div);
  const widthWithoutScrollbar = $('div', div).innerWidth();
  div.css('overflow-y', 'auto');
  const widthWithScrollbar = $('div', div).innerWidth();
  $(div).remove();
  return widthWithoutScrollbar - widthWithScrollbar;
}

export function isRunningTest() {
  return !!$('body[data-running-test]').length;
}

export function currentLanguage() {
  return $('html').prop('lang');
}

export function redirectAfterAction(url, methodType) {
  if (methodType === 'delete') {
    $.ajax({
      url,
      method: 'DELETE',
      success: (resp) => {
        window.location = resp.redirect_to || url;
      },
    });
  } else {
    window.location = url;
  }
}

export function allLinksSelectors() {
  let selector = "a:not([href='#'])";
  selector += ':not([data-dialog])';
  selector += ':not([data-popup])';
  selector += ":not([data-toggle='modal'])";
  selector += ":not([href^='mailto'])";
  selector += ":not([href^='javascript'])";
  selector += ":not([target='_blank'])";
  selector += ':not(.ember-view)';
  selector += ':not([data-ember-action])';
  selector += ":not([target='_blank'])";
  selector +=
    ',.calibration .selection_criteria .modify,.calibration .selection_criteria .summary';
  return selector;
}

export function allLinks(parent) {
  return parent.find(allLinksSelectors());
}

export function round(number, precision) {
  const factor = Math.pow(10, precision);
  const rounded = Math.round(number * factor) / factor;
  return rounded.toFixed(precision);
}

export function writeToClipboard(text) {
  if (!document.execCommand) return false;
  let success = true;
  const input = $(`<textarea>${text}</textarea>`);
  $(document.body).append(input);
  input.trigger('select');
  try {
    document.execCommand('copy');
  } catch {
    success = false;
  }
  input.remove();
  return success;
}

export function animationOff() {
  return $("meta[name='animations']").attr('content') === 'off';
}

export function isEmpty(value) {
  if ($.isPlainObject(value)) {
    return $.isEmptyObject(value);
  } else if (Array.isArray(value)) {
    return value.length == 0;
  } else {
    return !!value;
  }
}

export function groupBy(collection, func) {
  const result = {};
  collection.forEach((value) => {
    const key = func(value);
    const values = result[key] || [];
    values.push(value);
    result[key] = values;
  });
  return result;
}

export function isEmber() {
  return !!$('#ember').length;
}

export function triggerOnLoad() {
  $(window).trigger('load');
}

export function minBy(array, propertyFunc) {
  if (!Array.isArray(array))
    throw `minBy expects an array, was ${typeof array}`;
  const sortedArray = [...array];
  sortedArray.sort((a, b) => propertyFunc(a) - propertyFunc(b));
  return sortedArray[0];
}

export function serializeForm(form) {
  return $(form)
    .find(':input')
    .filter((_index, element) => {
      return $(element).val() !== null && $(element).val() !== '';
    })
    .serialize();
}

export function hideEmptyReadOnlyFields($form, selector) {
  $form.find(selector).each((_, group) => {
    const $group = $(group);
    $group
      .find(':input[readonly], :input[data-field-readonly]')
      .each((_, input) => {
        const $input = $(input);
        if (blank($input.val())) {
          let $input_or_wrapper;
          if ($input.parent('.jquery-ms-container').length) {
            $input_or_wrapper = $input.parent('.jquery-ms-container');
          } else {
            $input_or_wrapper = $input;
          }
          $input_or_wrapper.parent('p').addClass('d-none');
        }
      });
    const $content = $group.find('.group_content');
    // Do not hide the approval group in the external request edit page
    if ($content.contents().length === 0) {
      $group.addClass('d-none');
    } else {
      // Applies to direct information rows only
      const $contentDirectP = $content.find('> p');
      const $visibleContentP = $contentDirectP.filter(':visible');
      if ($contentDirectP.length > 0 && $visibleContentP.length === 0) {
        $group.addClass('d-none');
      }
    }
  });
}

export function disableSelect(element) {
  if (element.data('multipleSelect')) {
    element.data('multipleSelect').disable();
  } else {
    element.prop('disabled', true);
  }
}

export function enableSelect(element) {
  if (element.data('multipleSelect')) {
    element.data('multipleSelect').enable();
  } else {
    element.prop('disabled', false);
  }
}

export const extTranslations = {
  cs: {
    'Edit link': 'Upravit odkaz',
    'Remove link': 'Odebrat odkaz',
  },
  da: {
    'Edit link': 'Ændre link',
    'Remove link': 'Fjern link',
  },
  de: {
    'Edit link': 'Link ändern',
    'Remove link': 'Link entfernen',
  },
  el: {
    'Edit link': 'Τροποποιήστε τον σύνδεσμο',
    'Remove link': 'Κατάργηση συνδέσμου',
  },
  en: {
    'Edit link': 'Modify link',
    'Remove link': 'Remove link',
  },
  es: {
    'Edit link': 'Modificar el enlace',
    'Remove link': 'Remover enlace',
  },
  et: {
    'Edit link': 'Muutke linki',
    'Remove link': 'Eemalda link',
  },
  fi: {
    'Edit link': 'Muokata linkkiä',
    'Remove link': 'Poista linkki',
  },
  fr: {
    'Edit link': 'Modifier le lien',
    'Remove link': 'Supprimer le lien',
  },
  hr: {
    'Edit link': 'Izmijenite vezu',
    'Remove link': 'Ukloni vezu',
  },
  hu: {
    'Edit link': 'Módosítsa a linket',
    'Remove link': 'Link eltávolítása',
  },
  it: {
    'Edit link': 'Modifica il collegamentoo',
    'Remove link': 'Rimuovi collegamento',
  },
  lt: {
    'Edit link': 'Modifikuoti nuorodą',
    'Remove link': 'Pašalinti nuorodą',
  },
  nb: {
    'Edit link': 'Endre lenke',
    'Remove link': 'Fjern lenken',
  },
  nl: {
    'Edit link': 'Link wijzigen',
    'Remove link': 'Link verwijderen',
  },
  pl: {
    'Edit link': 'Zmodyfikuj link',
    'Remove link': 'Usuń link',
  },
  pt: {
    'Edit link': 'Modificar link',
    'Remove link': 'Remover link',
  },
  ro: {
    'Edit link': 'Modificați link -ul',
    'Remove link': 'Eliminați linkul',
  },
  sk: {
    'Edit link': 'Upraviť odkaz',
    'Remove link': 'Odstrániť odkaz',
  },
  sv: {
    'Edit link': 'Ändra länk',
    'Remove link': 'Ta bort länken',
  },
  tr: {
    'Edit link': 'Bağlantıyı düzenle',
    'Remove link': 'Bağlantıyı kaldır',
  },
};

const da = {
  Markdown: 'Markdown',
  WYSIWYG: 'WYSIWYG',
  Write: 'Skrive',
  Preview: 'Forhåndsvisning',
  Headings: 'Overskrifter',
  Paragraph: 'Afsnit',
  Bold: 'Fremhævet',
  Italic: 'Kursiv',
  Strike: 'Strejke',
  'Inline code': 'Inline kode',
  Line: 'Linje',
  Blockquote: 'Blokcitat',
  'Unordered list': 'Uordnet liste',
  'Ordered list': 'Bestilt liste',
  Task: 'Opgave',
  Indent: 'Indryk',
  Outdent: 'Uddyb',
  'Insert link': 'Indsæt link',
  'Insert codeBlock': 'Indsæt kodeblok',
  'Insert table': 'Indsæt tabel',
  'Insert image': 'Indsæt billede',
  Heading: 'Overskrift',
  'Image URL': 'Billed-URL',
  'Select image file': 'Vælg billedfil',
  'Choose a file': 'Vælg en fil',
  'No file': 'Ingen fil',
  Description: 'Beskrivelse',
  OK: 'Okay',
  More: 'Mere',
  Cancel: 'Afbestille',
  File: 'Fil',
  URL: 'URL',
  'Link text': 'Linktekst',
  'Add row to up': 'Tilføj række til op',
  'Add row to down': 'Tilføj række til ned',
  'Add column to left': 'Tilføj kolonne til venstre',
  'Add column to right': 'Tilføj kolonne til højre',
  'Remove row': 'Fjern række',
  'Remove column': 'Fjern kolonne',
  'Align column to left': 'Juster kolonne til venstre',
  'Align column to center': 'Juster kolonnen til midten',
  'Align column to right': 'Juster kolonne til højre',
  'Remove table': 'Fjern bordet',
  'Would you like to paste as table?': 'Vil du indsætte som tabel?',
  'Text color': 'Tekstfarve',
  'Auto scroll enabled': 'Automatisk rulning aktiveret',
  'Auto scroll disabled': 'Automatisk rulning deaktiveret',
  'Choose language': 'Vælg sprog',
};

const el = {
  Markdown: 'Χαμήλωση τιμής',
  WYSIWYG: 'WYSIWYG',
  Write: 'Γράφω',
  Preview: 'Προεπισκόπηση',
  Headings: 'Επικεφαλίδες',
  Paragraph: 'Παράγραφος',
  Bold: 'Τολμηρός',
  Italic: 'Πλάγια γραφή',
  Strike: 'Απεργία',
  'Inline code': 'Ενσωματωμένος κωδικός',
  Line: 'Γραμμή',
  Blockquote: 'Blockquote',
  'Unordered list': 'Λίστα χωρίς σειρά',
  'Ordered list': 'Διατεταγμένη λίστα',
  Task: 'Εργο',
  Indent: 'Δαντελώνω',
  Outdent: 'Outdent',
  'Insert link': 'Εισαγωγή συνδέσμου',
  'Insert codeBlock': 'Εισαγάγετε codeBlock',
  'Insert table': 'Εισαγωγή πίνακα',
  'Insert image': 'Εισαγωγή εικόνας',
  Heading: 'Επικεφαλίδα',
  'Image URL': 'Διεύθυνση URL εικόνας',
  'Select image file': 'Επιλέξτε αρχείο εικόνας',
  'Choose a file': 'Επιλέξτε ένα αρχείο',
  'No file': 'Κανένα αρχείο',
  Description: 'Περιγραφή',
  OK: 'Εντάξει',
  More: 'Περισσότερο',
  Cancel: 'Ματαίωση',
  File: 'Αρχείο',
  URL: 'URL',
  'Link text': 'Κείμενο συνδέσμου',
  'Add row to up': 'Προσθήκη σειράς προς τα επάνω',
  'Add row to down': 'Προσθήκη σειράς προς τα κάτω',
  'Add column to left': 'Προσθήκη στήλης προς τα αριστερά',
  'Add column to right': 'Προσθήκη στήλης προς τα δεξιά',
  'Remove row': 'Αφαίρεση σειράς',
  'Remove column': 'Κατάργηση στήλης',
  'Align column to left': 'Στοίχιση στήλης προς τα αριστερά',
  'Align column to center': 'Ευθυγραμμίστε τη στήλη στο κέντρο',
  'Align column to right': 'Στοίχιση στήλης προς τα δεξιά',
  'Remove table': 'Αφαιρέστε τον πίνακα',
  'Would you like to paste as table?': 'Θα θέλατε να επικολλήσετε ως τραπέζι;',
  'Text color': 'Χρώμα κειμένου',
  'Auto scroll enabled': 'Ενεργοποιήθηκε η αυτόματη κύλιση',
  'Auto scroll disabled': 'Η αυτόματη κύλιση είναι απενεργοποιημένη',
  'Choose language': 'Διάλεξε γλώσσα',
};

const sk = {
  Markdown: 'Markdown',
  WYSIWYG: 'WYSIWYG',
  Write: 'Napíšte',
  Preview: 'Náhľad',
  Headings: 'Nadpisy',
  Paragraph: 'Odsek',
  Bold: 'Tučné',
  Italic: 'kurzíva',
  Strike: 'Štrajkovať',
  'Inline code': 'Vložený kód',
  Line: 'Linka',
  Blockquote: 'Blockquote',
  'Unordered list': 'Neusporiadaný zoznam',
  'Ordered list': 'Objednaný zoznam',
  Task: 'Úloha',
  Indent: 'Zarážka',
  Outdent: 'Predsadiť',
  'Insert link': 'Vložiť odkaz',
  'Insert codeBlock': 'Vložte blok kódu',
  'Insert table': 'Vložiť tabuľku',
  'Insert image': 'Vložte obrázok',
  Heading: 'Smerovanie',
  'Image URL': 'Adresa URL obrázka',
  'Select image file': 'Vyberte súbor obrázka',
  'Choose a file': 'Vyberte súbor',
  'No file': 'Žiadny súbor',
  Description: 'Popis',
  OK: 'OK',
  More: 'Viac',
  Cancel: 'Zrušiť',
  File: 'Súbor',
  URL: 'URL',
  'Link text': 'Text odkazu',
  'Add row to up': 'Pridať riadok nahor',
  'Add row to down': 'Pridať riadok nadol',
  'Add column to left': 'Pridať stĺpec vľavo',
  'Add column to right': 'Pridať stĺpec doprava',
  'Remove row': 'Odstrániť riadok',
  'Remove column': 'Odstráňte stĺpec',
  'Align column to left': 'Zarovnať stĺpec doľava',
  'Align column to center': 'Zarovnajte stĺpec na stred',
  'Align column to right': 'Zarovnať stĺpec doprava',
  'Remove table': 'Odstráňte stôl',
  'Would you like to paste as table?': 'Chcete prilepiť ako tabuľku?',
  'Text color': 'Farba textu',
  'Auto scroll enabled': 'Automatické posúvanie je povolené',
  'Auto scroll disabled': 'Automatické posúvanie je vypnuté',
  'Choose language': 'Vyberte jazyk',
};

const lt = {
  Markdown: 'Užsirašyk',
  WYSIWYG: 'WYSIWYG',
  Write: 'Rašyti',
  Preview: 'Peržiūra',
  Headings: 'Antraštės',
  Paragraph: 'Pastraipa',
  Bold: 'Paryškintas',
  Italic: 'Kursyvas',
  Strike: 'Streikas',
  'Inline code': 'Inline kodas',
  Line: 'Linija',
  Blockquote: 'Citatos blokas',
  'Unordered list': 'Netvarkingas sąrašas',
  'Ordered list': 'Užsakytas sąrašas',
  Task: 'Užduotis',
  Indent: 'Įtrauka',
  Outdent: 'Outdent',
  'Insert link': 'Įterpti nuorodą',
  'Insert codeBlock': 'Įdėkite kodo bloką',
  'Insert table': 'Įdėkite lentelę',
  'Insert image': 'Įterpti vaizdą',
  Heading: 'Antraštė',
  'Image URL': 'Vaizdo URL',
  'Select image file': 'Pasirinkite vaizdo failą',
  'Choose a file': 'Pasirinkite failą',
  'No file': 'Nėra failo',
  Description: 'apibūdinimas',
  OK: 'Gerai',
  More: 'Daugiau',
  Cancel: 'Atšaukti',
  File: 'Failas',
  URL: 'URL',
  'Link text': 'Nuorodos tekstas',
  'Add row to up': 'Pridėti eilutę į viršų',
  'Add row to down': 'Pridėti eilutę į apačią',
  'Add column to left': 'Pridėti stulpelį į kairę',
  'Add column to right': 'Pridėti stulpelį dešinėje',
  'Remove row': 'Pašalinti eilutę',
  'Remove column': 'Pašalinti stulpelį',
  'Align column to left': 'Sulygiuoti stulpelį į kairę',
  'Align column to center': 'Sulygiuoti stulpelį su centru',
  'Align column to right': 'Sulygiuoti stulpelį į dešinę',
  'Remove table': 'Pašalinti lentelę',
  'Would you like to paste as table?': 'Ar norėtumėte įklijuoti kaip lentelę?',
  'Text color': 'Teksto spalva',
  'Auto scroll enabled': 'Automatinis slinkimas įjungtas',
  'Auto scroll disabled': 'Automatinis slinkimas išjungtas',
  'Choose language': 'Pasirinkite kalbą',
};

const hu = {
  Markdown: 'Árleszállítás',
  WYSIWYG: 'WYSIWYG',
  Write: 'Ír',
  Preview: 'Előnézet',
  Headings: 'Címsorok',
  Paragraph: 'Bekezdés',
  Bold: 'Bátor',
  Italic: 'Dőlt',
  Strike: 'Sztrájk',
  'Inline code': 'Inline kód',
  Line: 'Vonal',
  Blockquote: 'Idézetblokk',
  'Unordered list': 'Rendezetlen lista',
  'Ordered list': 'Rendezett lista',
  Task: 'Feladat',
  Indent: 'Behúzás',
  Outdent: 'Outdent',
  'Insert link': 'Link beszúrása',
  'Insert codeBlock': 'Helyezze be a kódblokkot',
  'Insert table': 'Táblázat beszúrása',
  'Insert image': 'Kép beszúrása',
  Heading: 'Cím',
  'Image URL': 'Kép URL-je',
  'Select image file': 'Válassza ki a képfájlt',
  'Choose a file': 'Válasszon egy fájlt',
  'No file': 'Nincs fájl',
  Description: 'Leírás',
  OK: 'rendben',
  More: 'Több',
  Cancel: 'Megszünteti',
  File: 'Fájl',
  URL: 'URL',
  'Link text': 'Link szövege',
  'Add row to up': 'Sor hozzáadása felfelé',
  'Add row to down': 'Sor hozzáadása lefelé',
  'Add column to left': 'Oszlop hozzáadása balra',
  'Add column to right': 'Oszlop hozzáadása jobbra',
  'Remove row': 'Sor eltávolítása',
  'Remove column': 'Oszlop eltávolítása',
  'Align column to left': 'Oszlop igazítása balra',
  'Align column to center': 'Oszlop igazítása középre',
  'Align column to right': 'Oszlop igazítása jobbra',
  'Remove table': 'Távolítsa el a táblázatot',
  'Would you like to paste as table?': 'Szeretné táblázatként beilleszteni?',
  'Text color': 'Szöveg szín',
  'Auto scroll enabled': 'Automatikus görgetés engedélyezve',
  'Auto scroll disabled': 'Az automatikus görgetés letiltva',
  'Choose language': 'Válassz nyelvet',
};

const ro = {
  Markdown: 'Markdown',
  WYSIWYG: 'WYSIWYG',
  Write: 'Scrie',
  Preview: 'previzualizare',
  Headings: 'Titluri',
  Paragraph: 'Paragraf',
  Bold: 'Îndrăzneţ',
  Italic: 'Cursiv',
  Strike: 'Grevă',
  'Inline code': 'Cod inline',
  Line: 'Linia',
  Blockquote: 'Blockquote',
  'Unordered list': 'Lista neordonata',
  'Ordered list': 'Lista ordonata',
  Task: 'Sarcină',
  Indent: 'indentare',
  Outdent: 'Outdent',
  'Insert link': 'Introduceți linkul',
  'Insert codeBlock': 'Introduceți codeBlock',
  'Insert table': 'Inserați tabelul',
  'Insert image': 'Inserați imaginea',
  Heading: 'Titlu',
  'Image URL': 'Imagine URL',
  'Select image file': 'Selectați fișierul imagine',
  'Choose a file': 'Alegeți un fișier',
  'No file': 'Niciun fisier',
  Description: 'Descriere',
  OK: 'Bine',
  More: 'Mai mult',
  Cancel: 'Anulare',
  File: 'Fişier',
  URL: 'URL',
  'Link text': 'Link text',
  'Add row to up': 'Adăugați rând în sus',
  'Add row to down': 'Adăugați rând în jos',
  'Add column to left': 'Adăugați coloană la stânga',
  'Add column to right': 'Adăugați coloană în dreapta',
  'Remove row': 'Eliminați rândul',
  'Remove column': 'Eliminați coloana',
  'Align column to left': 'Aliniați coloana la stânga',
  'Align column to center': 'Aliniați coloana la centru',
  'Align column to right': 'Aliniați coloana la dreapta',
  'Remove table': 'Scoateți masa',
  'Would you like to paste as table?': 'Doriți să lipiți ca tabel?',
  'Text color': 'Culoarea textului',
  'Auto scroll enabled': 'Defilarea automată este activată',
  'Auto scroll disabled': 'Defilarea automată a fost dezactivată',
  'Choose language': 'Alege limba',
};

const et = {
  Markdown: 'Allahindlus',
  WYSIWYG: 'WYSIWYG',
  Write: 'Kirjutage',
  Preview: 'Eelvaade',
  Headings: 'Pealkirjad',
  Paragraph: 'Lõik',
  Bold: 'Julge',
  Italic: 'Kursiiv',
  Strike: 'Streik',
  'Inline code': 'Tekstisisene kood',
  Line: 'Liin',
  Blockquote: 'Plokktsitaat',
  'Unordered list': 'Järjestamata nimekiri',
  'Ordered list': 'Tellitud nimekiri',
  Task: 'Ülesanne',
  Indent: 'Taane',
  Outdent: 'Outdent',
  'Insert link': 'Sisesta link',
  'Insert codeBlock': 'Sisestage CodeBlock',
  'Insert table': 'Sisestage tabel',
  'Insert image': 'Sisesta pilt',
  Heading: 'Pealkiri',
  'Image URL': 'Pildi URL',
  'Select image file': 'Valige pildifail',
  'Choose a file': 'Valige fail',
  'No file': 'Faili pole',
  Description: 'Kirjeldus',
  OK: 'Okei',
  More: 'Rohkem',
  Cancel: 'Tühista',
  File: 'Fail',
  URL: 'URL',
  'Link text': 'Lingi tekst',
  'Add row to up': 'Lisa rida üles',
  'Add row to down': 'Lisa rida alla',
  'Add column to left': 'Lisa veerg vasakule',
  'Add column to right': 'Lisa veerg paremale',
  'Remove row': 'Eemalda rida',
  'Remove column': 'Eemalda veerg',
  'Align column to left': 'Joonda veerg vasakule',
  'Align column to center': 'Joonda veerg keskele',
  'Align column to right': 'Joonda veerg paremale',
  'Remove table': 'Eemalda tabel',
  'Would you like to paste as table?': 'Kas soovite kleepida tabelina?',
  'Text color': 'Teksti värv',
  'Auto scroll enabled': 'Automaatne kerimine on lubatud',
  'Auto scroll disabled': 'Automaatne kerimine keelatud',
  'Choose language': 'Valige keel',
};

export const languages = {
  da,
  el,
  sk,
  lt,
  hu,
  ro,
  et,
};
